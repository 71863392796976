import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
import Seo from "../components/Seo"
import "normalize.css"

const information = ({
  data: {
    allContentfulProduct: { nodes: articles },
  },
}) => {
  return (
    <Layout>
      <Seo title="Information" description="this is Information" />
      <main className="page">
        <section className="about-page">
          <article>
            <span className="infotitre">Who are we?</span>
            <br /> After his graduation from "l'Ecole Supérieure des Arts
            Modernes de Paris" Jean Luc Théallet (Born in france) has worked for
            20 years as a graphic designer. In 1997 he is going back to Paris
            but this time to learn the art of Icons Painting. In 2003 he is
            opening the shop "l'Icône" at 58 rue de la Grotte in Lourdes. This
            shop is specialized in religious antiquity and you can find a lot of
            piece of art, collector's Russian icons, status, relics and
            reliquaries, ornaments, goldsmiths, Ex-voto.... In 2012, with his
            wife Christine, they are opening a new shop, "l'ExVoto" at 57 rue de
            la Grotte which is now located since 2015 at 30 Avenue Bernadette
            Soubirous, close to the entrance of the sanctuary (Saint-Joseph's
            Doors). In exclusivity, in those two shops you will be able to find
            different reproductions of Our Lady from Lourdes's icon, the
            Apparition icon, Saint Bernadette's Icon and Our Lady of Philermos ,
            handpainted by Jean Luc Théallet.
            <span>
              <Link to="/contact" className="btn">
                Contact us!
              </Link>
            </span>
          </article>
          <StaticImage
            src="../assets/images/icone.png"
            alt="icone of Marie"
            className="about-img"
            height={500}
          />
        </section>
        <section className="featured-recipes"></section>
      </main>
    </Layout>
  )
}
export const query = graphql`
  {
    allContentfulProduct(
      sort: { fields: name, order: ASC }
      filter: { featured: { eq: true } }
    ) {
      nodes {
        id
        name
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  }
`

export default information
